<script setup lang="ts">
import type { Collections } from '@nuxt/content'

const props = defineProps<{
  col: string
}>()

const route = useRoute()

const { data } = await useAsyncData('content:query-collection:' + route.path, async () => {
  const collection = props.col as keyof Collections
  const content = await queryCollection(collection).all()
  return content
})
</script>

<template>
  <DebugDumpArray :items="data" />
</template>
